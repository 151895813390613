import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProcessInvitationMailsByServiceType } from "../api";

export const InvitationMailForm = ({ serviceType }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const locales = {
    fi: t("Locales.Fi"),
    sv: t("Locales.Sv"),
    en: t("Locales.En"),
  };

  useEffect(() => {
    async function fetchInvitationMails() {
      if (!isLoading) {
        setIsLoading(true);
        const response = await getProcessInvitationMailsByServiceType(
          serviceType ?? "None"
        );
        setTemplates(response);
        setIsLoading(false);
      }
    }
    fetchInvitationMails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p className="text-sm">{t("InvitationMails.Description")}</p>
      {!isLoading ? (
        <>
          {templates && templates.map((template, index) => (
            <div key={`invitation_mail_${index}`}>
              <h3>{locales[template.lang]} | {t("Generic.Email")}</h3>
              <div className="container rounded-lg bg-white shadow p-4 mb-6 divide-y divide-gray-200 ">
                <iframe
                  srcDoc={template.email}
                  title="Invitation Mail"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
              <h3>{locales[template.lang]} | {t("Generic.Sms")}</h3>
              <div className="container rounded-lg bg-white shadow p-4 mb-6 divide-y divide-gray-200 text-sm whitespace-pre-wrap">
                {template.sms}
              </div>
            </div>
          ))}
        </>
      ) : (
        <p>{isLoading ? t("Overlay.Loading") : ""}</p>
      )}
    </>
  );
};
